import { Box } from '@mui/material'

type LogoProps = {
    width?: string | number
    height?: string | number
}

export function Logo({ width, height }: LogoProps) {
    return (
        <Box width={width} height={height}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1024 315.01"
                xmlSpace="preserve"
                width={width}
                height={height}
            >
                <path d="M34.05 302.18V85.15h147.06v35.63H73.89v60.9h93.94v35.63H73.89v84.87H34.05zM490.77 173.58c-3.46-1.08-9.07-1.62-16.84-1.62-14.25 0-25.32 4.27-33.2 12.79-7.89 8.53-11.82 20.36-11.82 35.47v81.95H391V138.92h34.98v30.12c4.75-10.37 11.44-18.35 20.08-23.97 8.63-5.61 18.57-8.42 29.8-8.42 4.97 0 9.93.76 14.9 2.27v34.66zM606.73 134.39c11.44 0 21.38 3.03 29.8 9.07 8.42 6.05 14.79 14.79 19.11 26.24 5.18-11.23 12.9-19.92 23.16-26.08 10.25-6.15 22.19-9.23 35.79-9.23 19 0 33.79 6.15 44.38 18.46 10.58 12.31 15.87 29.59 15.87 51.83v97.5h-37.9v-95.23c0-12.96-2.75-22.73-8.26-29.32-5.51-6.58-13.66-9.88-24.46-9.88-11.66 0-20.84 3.89-27.53 11.66-6.7 7.77-10.04 18.46-10.04 32.07v90.7h-37.9v-95.23c0-12.96-2.75-22.73-8.26-29.32-5.51-6.58-13.55-9.88-24.13-9.88-11.88 0-21.11 3.94-27.7 11.82-6.59 7.89-9.88 18.95-9.88 33.2v89.4h-37.9V138.92h34.98v24.62c5.18-9.5 12.15-16.73 20.89-21.7 8.76-4.97 18.75-7.45 29.98-7.45zM923.52 85.15l82.28 217.03h-43.41l-17.49-50.86h-87.46l-17.17 50.86h-43.08l82.28-217.03h44.05zm-54.74 132.49h64.79l-32.39-95.23-32.4 95.23z" />
                <defs>
                    <path id="a" d="M173.01 99.15h215.86v215.86H173.01z" />
                </defs>
                <clipPath id="b">
                    <use
                        xlinkHref="#a"
                        style={{
                            overflow: 'visible',
                        }}
                    />
                </clipPath>
                <path
                    style={{
                        clipPath: 'url(#b)',
                        stroke: '#000',
                        strokeWidth: 5.5532,
                        strokeLinejoin: 'bevel',
                        strokeMiterlimit: 10,
                    }}
                    d="M271.95 129.02h17.98v73.56h-17.98v-73.56z"
                />
                <path
                    d="M327.94 154.77 317.78 169c16.08 11.5 26.12 30.28 26.12 51.57 0 35.04-27.92 62.96-62.96 62.96s-62.96-27.92-62.96-62.96c0-21.29 10.02-40.09 26.1-51.6l-10.17-14.23c-20.52 14.68-33.93 38.67-33.93 65.83 0 44.71 36.24 80.95 80.95 80.95s80.95-36.24 80.95-80.95c.01-27.16-13.42-51.13-33.94-65.8z"
                    style={{
                        clipPath: 'url(#b)',
                        fill: 'red',
                        stroke: 'red',
                        strokeWidth: 5.5532,
                        strokeLinejoin: 'bevel',
                        strokeMiterlimit: 10,
                    }}
                />
                <defs>
                    <path
                        id="c"
                        d="M1013 62.08H853v-55h160v55zm-142 1H723v59h148v-59zm13-1h-54v1h54v-1z"
                    />
                </defs>
                <clipPath id="d">
                    <use
                        xlinkHref="#c"
                        style={{
                            overflow: 'visible',
                        }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath: 'url(#d)',
                    }}
                >
                    <defs>
                        <path id="e" d="M723 13.08h281v99H723z" />
                    </defs>
                    <clipPath id="f">
                        <use
                            xlinkHref="#e"
                            style={{
                                overflow: 'visible',
                            }}
                        />
                    </clipPath>
                    <path
                        d="M664.37 30.43c15.9-.13 30.14 5.17 42.11 15.62 11.39 9.93 20.34 22.24 30.68 33.19 10.6 11.23 23.01 20.85 38.06 25.15 16.86 4.81 35.78 3.4 51.69-3.91 28.27-13 40.33-43.58 64.08-61.89 13.05-10.06 29.47-13.95 45.76-11.92 15.09 1.88 27.59 10.01 38.13 20.55 11.71 11.71 21.27 25.47 33.79 36.39 14.47 12.62 31.66 19.44 50.95 19.28 8.04-.07 8.06-12.57 0-12.5-15.9.13-30.14-5.17-42.12-15.62-11.39-9.93-20.34-22.24-30.68-33.19-10.6-11.23-23.01-20.85-38.06-25.15-16.86-4.81-35.78-3.4-51.69 3.91-28.27 13-40.33 43.58-64.08 61.89-13.05 10.06-29.47 13.95-45.76 11.92-15.09-1.88-27.59-10.01-38.13-20.55-11.71-11.71-21.27-25.47-33.78-36.39-14.47-12.62-31.66-19.44-50.95-19.28-8.04.07-8.06 12.57 0 12.5z"
                        style={{
                            clipPath: 'url(#f)',
                        }}
                    />
                </g>
            </svg>
        </Box>
    )
}
