import React, { useContext } from 'react'
import TopBar from './TopBar'
import { useAADAccount } from '../utils/auth'
import { useTranslation } from 'react-i18next'
import { ENVIRONMENT, GET_USER, GetUserResponse } from '../utils/api'
import { Chip, Collapse, Link, Stack, Typography } from '@mui/material'
import FeedbackIcon from '@mui/icons-material/Feedback'
import { DevSettingsContext } from '../utils/DevSettingsContext'
import { useQuery } from '@apollo/client'
import ExploreIcon from '@mui/icons-material/Explore'
import TagIcon from '@mui/icons-material/Tag'
import {
    parseUser,
    SMALL_SCREEN_BREAKPOINT,
    useWindowSize,
} from '../utils/helpers'

type Props = {
    children: React.ReactNode
    titleLoading?: boolean
    longTitle?: string
}

export function PageWrapper(props: Props) {
    const account = useAADAccount(0)

    const [t] = useTranslation('common')
    const devSettingsContext = useContext(DevSettingsContext)

    const { data, error } = useQuery<GetUserResponse>(GET_USER)

    if (error) {
        console.error(error)
    }

    const [width] = useWindowSize()

    if (
        data?.user.userGroups?.some(
            (ug) =>
                ug.permissions.find((p) => p.name === 'DEVELOP_TABLE') !==
                undefined
        )
    ) {
        devSettingsContext.setIsDeveloper(true)
    }

    if (props.longTitle) {
        document.title = props.titleLoading
            ? `${t('appName')}`
            : props.longTitle
    }

    return account ? (
        <React.Fragment>
            <TopBar
                buttons={[
                    {
                        text: t('topBar.explore'),
                        urn: '/explore',
                        icon: <ExploreIcon />,
                    },
                    {
                        text: t('topBar.tags'),
                        urn: '/tags',
                        icon: <TagIcon />,
                    },
                ]}
                user={data?.user ? parseUser(data.user) : undefined}
            />

            <Collapse
                in={
                    ENVIRONMENT === 'development' &&
                    !devSettingsContext.hideDevDisclaimer
                }
                unmountOnExit
                mountOnEnter
            >
                <Stack
                    sx={{
                        alignItems: 'center',
                        maxWidth: '90vw',
                        textAlign: 'center',
                        marginTop:
                            width <= SMALL_SCREEN_BREAKPOINT
                                ? '50px'
                                : undefined,
                    }}
                    spacing={1}
                >
                    <span className="dev-disclaimer">{t('devEnvMessage')}</span>
                    <Link
                        variant="h4"
                        sx={{ marginTop: '10px' }}
                        href={t('productionLink')}
                    >
                        {t('visitProd')}
                    </Link>
                </Stack>
            </Collapse>
            <div id="wrapped-content">{props.children}</div>
            <Collapse
                in={
                    ENVIRONMENT === 'development' &&
                    !devSettingsContext.hideDevDisclaimer
                }
                unmountOnExit
                mountOnEnter
            >
                <Stack
                    sx={{
                        alignItems: 'center',
                        maxWidth: '90vw',
                        textAlign: 'center',
                    }}
                    spacing={1}
                >
                    <span className="dev-disclaimer">{t('devEnvMessage')}</span>
                    <Link
                        variant="h4"
                        sx={{ marginTop: '10px' }}
                        href={t('productionLink')}
                    >
                        {t('visitProd')}
                    </Link>
                </Stack>
            </Collapse>
            <footer>
                <Stack direction="column" spacing={1} alignItems="stretch">
                    <Typography>{t('footer.copyright')}</Typography>
                    <Typography
                        color="primary"
                        variant="caption"
                        alignSelf="center"
                    >
                        v{process.env.REACT_APP_VERSION}
                    </Typography>
                    <Chip
                        icon={<FeedbackIcon />}
                        label={t('footer.feedbackButtonText')}
                        variant="outlined"
                        component="a"
                        href={`mailto:it-digital.pills.feedback@abb.com?subject=${t(
                            'footer.feedbackEmailSubject'
                        )}`}
                        clickable
                        target="_blank"
                    />
                    <Link
                        sx={{ alignSelf: 'center' }}
                        href={t('footer.privacyPolicyURL')}
                        target="_blank"
                    >
                        {t('footer.privacyPolicyText')}
                    </Link>
                </Stack>
            </footer>
        </React.Fragment>
    ) : (
        <p>Error.</p>
    )
}

export default PageWrapper
