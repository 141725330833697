import { lazy, Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { PermissionGatedComponent } from './components/PermissionGatedComponent'
import { useQuery } from '@apollo/client'
import { GET_USER, GetUserResponse } from './utils/api'
import { FullScreenLoader } from './components/FullScreenLoader'

const Home = lazy(() => import('./pages/Home'))
const TestPage = lazy(() => import('./pages/TestPage'))
const LessonPage = lazy(() => import('./pages/LessonPage'))
const CoursePage = lazy(() => import('./pages/CoursePage'))
const SearchResults = lazy(() => import('./pages/SearchResults'))
const Tags = lazy(() => import('./pages/Tags'))
const Profile = lazy(() => import('./pages/Profile'))
const Explore = lazy(() => import('./pages/Explore'))
const Bookmarks = lazy(() => import('./pages/Bookmarks'))
const Groups = lazy(() => import('./pages/Groups'))
const CertificatesPage = lazy(() => import('./pages/CertificatesPage'))
const CertificatePage = lazy(() => import('./pages/CertificatePage'))
const ManageWorkGroup = lazy(() => import('./pages/ManageWorkGroup'))
const DevSettings = lazy(() => import('./pages/DevSettings'))
const Logout = lazy(() => import('./pages/Logout'))

export default function Routes() {
    const location = useLocation()

    const { data, error, loading } = useQuery<GetUserResponse>(GET_USER)

    const parsedUser = {
        completeName: `${data?.user.name} ${data?.user.surname}` || 'error',
        email: data?.user.email || 'error',
        error: error !== undefined,
        permissions:
            data?.user.userGroups
                ?.flatMap((ug) => ug.permissions)
                .filter((p, i, a) => a.indexOf(p) === i) || [],
    }

    return loading ? (
        <FullScreenLoader />
    ) : (
        <Suspense fallback={<FullScreenLoader />}>
            <Switch location={location}>
                <Route path="/logout">
                    <Logout />
                </Route>
                <Route path="/courses/:courseID/test">
                    <TestPage />
                </Route>
                <Route path="/courses/:courseID/:lessonIndex(\d+)">
                    <LessonPage />
                </Route>
                <Route path="/courses/:courseID">
                    <CoursePage />
                </Route>
                <Route path="/search/:searchText">
                    <SearchResults />
                </Route>
                <Route path="/tags">
                    <Tags />
                </Route>
                <Route path="/profile">
                    <Profile />
                </Route>
                <Route path="/explore">
                    <Explore />
                </Route>
                <Route path="/bookmarks">
                    <Bookmarks />
                </Route>
                <Route path="/classes">
                    <PermissionGatedComponent
                        requiredPermissionsNames={['GROUP_CREATE']}
                        user={parsedUser}
                    >
                        <Groups />
                    </PermissionGatedComponent>
                </Route>
                <Route path="/certificates" exact>
                    <CertificatesPage />
                </Route>
                <Route path="/certificates/:courseID">
                    <CertificatePage />
                </Route>
                <Route path="/manage-class/:workGroupID">
                    <PermissionGatedComponent
                        requiredPermissionsNames={['GROUP_CREATE']}
                        user={parsedUser}
                    >
                        <ManageWorkGroup />
                    </PermissionGatedComponent>
                </Route>
                <Route path="/dev-settings">
                    <DevSettings />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Suspense>
    )
}
