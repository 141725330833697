import React from 'react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'
import Select from 'react-select'
import { WillLocale } from '../utils/types'
import { storeLocale } from '../utils/localstorage'
import { getCurrentLocale } from '../utils/locale'

type Props = {
    locales: WillLocale[]
    className?: string
    style?: React.CSSProperties
}

export function LanguageSwitcher(props: Props) {
    const [, i18n] = useTranslation()
    const currentLocale = getCurrentLocale()

    const [width, setWidth] = React.useState(window.innerWidth)
    const breakpoint = 1000

    React.useEffect(() => {
        /* Inside of a "useEffect" hook add an event listener that updates
           the "width" state variable when the window size changes */
        window.addEventListener('resize', () => setWidth(window.innerWidth))

        /* passing an empty array as the dependencies of the effect will cause this
           effect to only run when the component mounts, and not each time it updates.
           We only want the listener to be added once */
    }, [])

    return (
        <div
            className={`language-switcher ${
                props.className ? props.className : ''
            }`}
            style={props.style}
        >
            <LanguageIcon className="language-switcher-icon" />
            <Select
                menuPlacement="auto"
                className="language-switcher-select"
                defaultValue={{
                    value: currentLocale.code,
                    label:
                        width <= breakpoint
                            ? currentLocale.icon
                            : currentLocale.name,
                }}
                onChange={(e) => {
                    if (e) {
                        i18n.changeLanguage(e.value)
                        storeLocale(
                            props.locales.filter(
                                (locale) => locale.code === e.value
                            )[0]
                        )
                        window.location.reload()
                    }
                }}
                value={{
                    value: currentLocale.code,
                    label:
                        width <= breakpoint
                            ? currentLocale.icon
                            : currentLocale.name,
                }}
                options={props.locales.map((locale) => {
                    return {
                        value: locale.code,
                        label: width <= breakpoint ? locale.icon : locale.name,
                    }
                })}
            />
        </div>
    )
}

export default LanguageSwitcher
