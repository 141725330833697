import { useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Course, Permission, User } from './types'

export function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight])
    useLayoutEffect(() => {
        let resizeEventTimer: any = null
        function updateSize() {
            if (resizeEventTimer) {
                clearTimeout(resizeEventTimer)
            }
            resizeEventTimer = setTimeout(
                () => setSize([window.innerWidth, window.innerHeight]),
                300
            )
        }
        window.addEventListener('resize', updateSize)
        setSize([window.innerWidth, window.innerHeight])
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size
}

export function useWindowWidth() {
    const [width, setWidth] = useState(0)
    useLayoutEffect(() => {
        let resizeEventTimer: any = null
        function updateSize() {
            if (resizeEventTimer) {
                clearTimeout(resizeEventTimer)
            }
            resizeEventTimer = setTimeout(
                () => setWidth(window.innerWidth),
                300
            )
        }
        window.addEventListener('resize', updateSize)
        setWidth(window.innerWidth)
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return width
}

export const useGetParams = () => {
    const location = useLocation()

    const params: { [key: string]: string | boolean } = {}

    location.search
        .substring(1, location.search.length)
        .split('&')
        .forEach((q) => {
            if (q.length > 0) {
                const [name, value] = q.split('=')
                params[name] =
                    value === 'true' ? true : value === 'false' ? false : value
            }
        })

    return params
}

export const computeCourseDifficulty = (course: Course) => {
    const levels = course.lessons.map((l) => l.level)
    if (levels.filter((l) => l === undefined).length > 0) {
        console.warn(`Undefined level found while computing course difficulty.`)
    }
    return course.lessons.length > 0
        ? course.lessons
              .map((l) => l.level)
              .filter((l) => l > -1)
              .reduce((a, b) => a + b, 0) / course.lessons.length
        : 0
}

export const SMALL_SCREEN_BREAKPOINT = 768

export const parseUser = (
    user: User
): {
    completeName: string
    email: string
    error: boolean
    permissions: Permission[]
} => {
    return {
        completeName: `${user.name} ${user.surname}` || 'error',
        email: user.email || 'error',
        error: false,
        permissions:
            user.userGroups
                ?.flatMap((ug) => ug.permissions)
                .filter((p, i, a) => a.indexOf(p) === i) || [],
    }
}

export const sleep = async (milliseconds: number) => {
    await new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export function partition<T>(
    array: T[],
    callback: (el: T, i: number, array: T[]) => boolean
) {
    return array.reduce(
        function (result, element, i) {
            callback(element, i, array)
                ? result[0].push(element)
                : result[1].push(element)

            return result
        },
        [[], []] as T[][]
    )
}
