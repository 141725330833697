import React from 'react'

export type DevSettingsContextType = {
    isDeveloper: boolean
    setIsDeveloper: (isDeveloper: boolean) => any
    hideDevDisclaimer: boolean
    setHideDevDisclaimer: (hideDevDisclaimer: boolean) => any
    spoofEmailAddress: boolean
    setSpoofEmailAddress: (spoofEmailAddress: boolean) => any
    fakeEmailAddress?: string
    setFakeEmailAddress: (fakeEmailAddress: string | undefined) => any
    showNotificationsEarlyAccess: boolean
    setShowNotificationsEarlyAccess: (
        showNotificationsEarlyAccess: boolean
    ) => any
}

export const DevSettingsContext = React.createContext<DevSettingsContextType>({
    isDeveloper: false,
    setIsDeveloper: (isDeveloper) => {},
    hideDevDisclaimer: false,
    setHideDevDisclaimer: (hideDevDisclaimer) => {},
    spoofEmailAddress: false,
    setSpoofEmailAddress: (spoofEmailAddress) => {},
    setFakeEmailAddress: (fakeEmailAddress) => {},
    showNotificationsEarlyAccess: false,
    setShowNotificationsEarlyAccess: (
        showNotificationsEarlyAccess: boolean
    ) => {},
})

export const storeDevSettingsContext = async (
    devSettingsContext: DevSettingsContextType
) => {}
