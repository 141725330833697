import {
    Badge,
    capitalize,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import React, { useCallback, useState } from 'react'
import { Message } from '../utils/types'
import { useTranslation } from 'react-i18next'
import { MessageDialog } from '../MessageDialog'
import CircleIcon from '@mui/icons-material/Circle'
import { compareAsc, formatRelative, sub } from 'date-fns'
import { partition } from '../utils/helpers'
import { getCurrentLocale } from '../utils/locale'

type NotificationItemProps = {
    message: Message
    onClick: (m: Message) => any
}

const messages: Message[] = [
    {
        id: '1',
        title: 'Test title',
        seen: false,
        created: new Date(Date.now()),
    },
    {
        id: '2',
        title: 'Test title',
        seen: true,
        created: sub(new Date(Date.now()), { minutes: 18 }),
    },
    {
        id: '3',
        title: 'Message with content title',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi consectetur euismod felis non mollis. Maecenas eget arcu dignissim, bibendum felis eget, condimentum tortor. Nullam finibus sem in quam luctus tristique in sed mi. Nunc nec risus id tellus vulputate pretium vitae id ligula. Pellentesque eget iaculis erat, in ultrices ante. Mauris ultrices auctor sodales. Nulla non fermentum libero, a sagittis orci. Ut vitae velit quis lorem consectetur viverra. Vestibulum sollicitudin lectus est, eu sodales orci tempus ut. Nullam in porttitor ante. Curabitur condimentum arcu quis molestie aliquet. In commodo lectus metus, eget porta nisl iaculis nec. Vivamus lobortis tristique tellus feugiat blandit. Sed id sodales elit, eu mattis ligula.',
        seen: true,
        created: sub(new Date(Date.now()), { hours: 15 }),
    },
    {
        id: '4',
        title: 'New message',
        seen: false,
        created: sub(new Date(Date.now()), { hours: 1 }),
    },
]

function NotificationMenuItem({ message, onClick }: NotificationItemProps) {
    const now = new Date(Date.now())

    return (
        <MenuItem
            key={message.id}
            sx={{ height: 50, width: 300 }}
            onClick={() => {
                if (onClick) {
                    onClick(message)
                }
            }}
        >
            <Tooltip title={message.title}>
                <>
                    <ListItemText
                        primary={
                            <Typography variant="inherit" noWrap>
                                {message.title}
                            </Typography>
                        }
                        secondary={
                            <Typography
                                variant="inherit"
                                fontSize={12}
                                color="primary"
                                noWrap
                            >
                                {capitalize(
                                    formatRelative(message.created, now, {
                                        locale: getCurrentLocale()
                                            .dateFNSLocale,
                                    })
                                )}
                            </Typography>
                        }
                    />
                    <ListItemIcon>
                        {!message.seen && (
                            <CircleIcon
                                sx={{ fontSize: 12 }}
                                color="secondary"
                            />
                        )}
                    </ListItemIcon>
                </>
            </Tooltip>
        </MenuItem>
    )
}

export function NotificationMenu() {
    const [t] = useTranslation('common')

    const [notiAnchorEl, setNotiAnchorEl] = React.useState<null | HTMLElement>(
        null
    )
    const notiOpen = Boolean(notiAnchorEl)
    const [notiDialogOpen, setNotiDialogOpen] = useState(false)
    const [currentMessage, setCurrentMessage] = useState<Message | null>(null)

    const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
        setNotiAnchorEl(event.currentTarget)
    }

    const handleNotificationsClose = () => {
        setNotiAnchorEl(null)
    }

    const [seen, unseen] = partition(
        messages.sort((m1, m2) => compareAsc(m1.created, m2.created)),
        (m) => m.seen
    )

    const onMessageClick = useCallback((m: Message) => {
        setNotiDialogOpen(true)
        setCurrentMessage(m)
        m.seen = true
    }, [])

    return (
        <>
            <Tooltip title={t('topBar.notiTitle') as string}>
                <IconButton
                    onClick={handleNotificationClick}
                    size="small"
                    sx={{ ml: 2 }}
                >
                    <Badge badgeContent={unseen.length} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 12,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                id="notifications-menu"
                anchorEl={notiAnchorEl}
                open={notiOpen}
                onClose={handleNotificationsClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {unseen.map((m) => (
                    <NotificationMenuItem
                        key={m.id}
                        message={m}
                        onClick={onMessageClick}
                    />
                ))}
                {seen.length > 0 && unseen.length > 0 && <Divider />}
                {seen.map((m) => (
                    <NotificationMenuItem
                        key={m.id}
                        message={m}
                        onClick={onMessageClick}
                    />
                ))}
            </Menu>
            <MessageDialog
                open={notiDialogOpen}
                message={currentMessage}
                handleClose={() => {
                    setNotiDialogOpen(false)
                }}
            />
        </>
    )
}
