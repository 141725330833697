import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
    text: string
    urn: string
}

export function TopBarButton(props: Props) {
    return (
        <NavLink
            className={(isActive) =>
                `topbar-button${isActive ? ' highlighted' : ''}`
            }
            to={props.urn}
        >
            {props.text}
        </NavLink>
    )
}

export default TopBarButton
