import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { Message } from './utils/types'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { format } from 'date-fns'
import { getCurrentLocale } from './utils/locale'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

type MessageDialogProps = {
    open: boolean
    message: Message | null
    handleClose?: () => any
}

export function MessageDialog({
    open,
    message,
    handleClose,
}: MessageDialogProps) {
    const [t] = useTranslation('common')
    const handleCloseWrapper = () => {
        if (handleClose) {
            handleClose()
        }
    }

    const markAsSeen = () => {
        console.log('Message seen')
    }

    useEffect(() => {
        if (open) {
            markAsSeen()
        } else {
            console.log('close')
        }
    }, [open])

    return (
        <Dialog open={open} onClose={handleCloseWrapper}>
            {message ? (
                <>
                    <DialogTitle>{message.title}</DialogTitle>
                    <DialogContent>
                        {message.content && (
                            <DialogContentText>
                                {message.content}
                            </DialogContentText>
                        )}
                        <DialogContentText
                            sx={{
                                marginTop: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <AccessTimeIcon sx={{ fontSize: 20 }} />
                            {format(message.created, 'Pp', {
                                locale: getCurrentLocale().dateFNSLocale,
                            })}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>
                            {t('topBar.notifications.dialogActionText')}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <p>error</p>
            )}
        </Dialog>
    )
}
