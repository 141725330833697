import {
    Category,
    Certificate,
    Content,
    ContentType,
    Course,
    Lesson,
    ManagedWorkGroup,
    Person,
    StrippedCourse,
    Tag,
    User,
} from './types'
import { gql } from '@apollo/client/core'

export const BACKEND_URI = process.env.REACT_APP_BACKEND_URI
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const API_URI = `${BACKEND_URI}/graphql`
export const VIDEOS_BASE_URI = `${BACKEND_URI}/static/video/`
export const FILES_BASE_URI = `${BACKEND_URI}/static/file/`

export interface SearchInput {
    contentType?: ('COURSE' | 'LESSON')[]
    text: string
    take?: number
    skip?: number
    completed?: boolean
    sort?: 'NAME' | 'POPULARITY' | 'RELEVANCE'
}

export interface SearchResponse {
    search: {
        items: ((Course | Lesson) & { __typename: string })[]
        totalCount: number
    }
}

export const SEARCH = gql`
    query Search(
        $text: String!
        $contentType: [ContentType!]
        $take: Int
        $skip: Int
        $completed: Boolean
        $sort: SortType!
    ) {
        search(
            text: $text
            contentType: $contentType
            take: $take
            skip: $skip
            completed: $completed
            sort: $sort
        ) {
            totalCount
            items {
                id
                name
                tags {
                    id
                    name
                }

                ... on Lesson {
                    lessonId
                    parent {
                        id
                        courseId
                        name
                        lessons {
                            id
                            lessonId
                        }
                    }
                    video {
                        id
                        uuid
                        thumbnailURL
                        durationSeconds
                    }
                    requiredTimeSeconds
                    level
                }

                ... on Course {
                    courseId
                    lessons {
                        video {
                            id
                            uuid
                            durationSeconds
                        }
                        requiredTimeSeconds
                        level
                    }
                    progress
                }
                __typename
            }
        }
    }
`

export interface GetCourseInput {
    input: {
        id: string
    }
}

export interface GetCourseResponse {
    course: Course
}

export const GET_COURSE = gql`
    query GetCourse($input: GetCourseInput!) {
        course(input: $input) {
            id
            courseId
            name
            description
            isFavorite
            viewableBy {
                id
                name
            }
            lessons {
                id
                lessonId
                isFavorite
                name
                description
                requiredTimeSeconds
                started
                finished
                video {
                    id
                    uuid
                    thumbnailURL
                    durationSeconds
                    formats {
                        id
                        extension
                    }
                }
                tags {
                    id
                    name
                }
                requirements {
                    id
                    text
                    description
                }
                usefulLinks {
                    id
                    name
                    url
                }
                downloads {
                    id
                    uuid
                    name
                }
                level
            }
            tags {
                id
                name
            }
            progress
            certificate {
                id
                earned
            }
            testUnavailable
            test {
                id
                questions {
                    id
                    text
                    answers {
                        id
                        text
                    }
                }
                minimumScore
            }
        }
    }
`

export interface GetTagsResponse {
    tags: Tag[]
}

export const GET_TAGS = gql`
    query GetTags {
        tags {
            id
            name
            usage
        }
    }
`

export interface GetUserResponse {
    user: User
}

export const GET_USER = gql`
    query GetUser {
        user {
            id
            name
            surname
            email
            userGroups {
                id
                name
                permissions {
                    id
                    name
                    description
                }
            }
            workGroups {
                id
                name
                requiredCourses {
                    id
                    courseId
                    name
                    lessons {
                        lessonId
                        started
                        finished
                        video {
                            id
                            thumbnailURL
                        }
                    }
                    test {
                        id
                    }
                    certificate {
                        id
                    }
                }
                manager {
                    id
                    name
                    surname
                    email
                }
            }
            managedWorkGroups {
                id
                name
                requiredCourses {
                    id
                    courseId
                }
                members {
                    id
                    userGroups {
                        id
                        name
                    }
                }
            }
        }
    }
`

export interface ExploreResponse {
    explore: Category[]
}

export const EXPLORE = gql`
    query Explore {
        explore {
            title
            items {
                id
                __typename
                name
                description

                ... on Course {
                    courseId
                    lessons {
                        id
                        level
                        video {
                            id
                            thumbnailURL
                        }
                    }
                }

                ... on Lesson {
                    parent {
                        id
                        courseId
                        name
                        lessons {
                            id
                        }
                    }
                    video {
                        id
                        thumbnailURL
                        durationSeconds
                    }
                    level
                }
            }
        }
    }
`

export interface BookmarkContentInput {
    contentId: string
    contentType: ContentType
}

export interface BookmarkContentResponse {
    bookmarkContent: Content
}

export const BOOKMARK_CONTENT = gql`
    mutation BookmarkContent($contentId: ID!, $contentType: ContentType!) {
        bookmarkContent(contentId: $contentId, contentType: $contentType) {
            id
            isFavorite
        }
    }
`

export interface UnbookmarkContentInput {
    contentId: string
    contentType: ContentType
}

export interface UnbookmarkContentResponse {
    unbookmarkContent: Content
}

export const UNBOOKMARK_CONTENT = gql`
    mutation UnbookmarkContent($contentId: ID!, $contentType: ContentType!) {
        unbookmarkContent(contentId: $contentId, contentType: $contentType) {
            id
            isFavorite
        }
    }
`

export interface StartLessonInput {
    lessonId: string
}

export interface StartLessonResponse {
    startLesson: {
        lessonID: string
        started: boolean
    }
}

export const START_LESSON = gql`
    mutation StartLesson($lessonId: ID!) {
        startLesson(lessonId: $lessonId) {
            lessonID
            started
        }
    }
`

export interface FinishLessonInput {
    lessonId: string
}

export interface FinishLessonResponse {
    finishLesson: {
        lessonID: string
        finished: boolean
    }
}

export const FINISH_LESSON = gql`
    mutation FinishLesson($lessonId: ID!) {
        finishLesson(lessonId: $lessonId) {
            lessonID
            finished
        }
    }
`

export interface GetBookmarksResponse {
    user: {
        bookmarks: ((Course | Lesson) & { __typename: string })[]
    }
}

export const GET_BOOKMARKS = gql`
    query GetBookmarks {
        user {
            id
            bookmarks {
                id
                name
                tags {
                    id
                    name
                }

                ... on Lesson {
                    lessonId
                    parent {
                        id
                        courseId
                        name
                        lessons {
                            id
                            lessonId
                        }
                    }
                    video {
                        id
                        uuid
                        thumbnailURL
                        durationSeconds
                    }
                    requiredTimeSeconds
                }

                ... on Course {
                    courseId
                    lessons {
                        video {
                            id
                            uuid
                            durationSeconds
                        }
                        requiredTimeSeconds
                    }
                    progress
                }
                __typename
            }
        }
    }
`

export interface SubmitAnswerInput {
    input: {
        answerId: string
    }
}

export interface SubmitAnswerResponse {
    submitAnswer: {
        testId: string
        questionId: string
        correctAnswer: boolean
        firstTime: boolean
    }
}

export const SUBMIT_ANSWER = gql`
    mutation SubmitAnswer($input: TestAnswerInput!) {
        submitAnswer(input: $input) {
            testId
            questionId
            correctAnswer
            firstTime
        }
    }
`

export interface GetCertificateInput {
    input: {
        courseId: string
    }
}

export interface GetCertificateResponse {
    certificate: Certificate
}

export const GET_CERTIFICATE = gql`
    query GetCertificate($input: GetCertificateInput!) {
        certificate(input: $input) {
            id
            score
            earned
            course {
                id
                name
            }
        }
    }
`

export interface GetCertificatesResponse {
    user: User
}

export const GET_CERTIFICATES = gql`
    query GetCertificates {
        user {
            id
            name
            surname
            email
            certificates {
                id
                score
                earned
                course {
                    id
                    name
                }
            }
        }
    }
`

export interface CreateWorkGroupInput {
    input: {
        workGroupName: string
    }
}

export interface CreateWorkGroupResponse {
    createWorkgroup: ManagedWorkGroup
}

export const CREATE_WORKGROUP = gql`
    mutation CreateWorkGroup($input: CreateWorkGroupInput!) {
        createWorkGroup(input: $input) {
            id
            name
            members {
                id
                name
                surname
                email
            }
        }
    }
`

export interface RemoveUsersFromWorkGroupInput {
    input: {
        workGroupID: string
        userIDs: string[]
    }
}

export interface RemoveUsersFormWorkGroupResponse {
    removeUsersFromWorkGroup: ManagedWorkGroup
}

export const REMOVE_USER_FROM_WORKGROUP = gql`
    mutation RemoveUsersFromWorkGroup($input: RemoveUsersFromWorkGroupInput!) {
        removeUsersFromWorkGroup(input: $input) {
            id
            name
            requiredCourses {
                id
                courseId
                name
                viewableBy {
                    id
                    name
                }
                lessons {
                    id
                    lessonId
                    name
                    started
                    finished
                    video {
                        id
                        thumbnailURL
                    }
                }
            }
            members {
                id
                name
                surname
                email
                certificates {
                    id
                    course {
                        id
                        courseId
                    }
                }
                inProgressCourses {
                    id
                    courseId
                    name
                    description
                    lessons {
                        id
                        lessonId
                        name
                        started
                        finished
                    }
                }
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export interface AddUsersToWorkGroupInput {
    input: {
        workGroupID: string
        userIDs: string[]
    }
}

export interface AddUsersToWorkGroupResponse {
    addUsersToWorkGroup: ManagedWorkGroup
}

export const ADD_USER_TO_WORKGROUP = gql`
    mutation AddUsersToWorkGroup($input: AddUsersToWorkGroupInput!) {
        addUsersToWorkGroup(input: $input) {
            id
            name
            requiredCourses {
                id
                courseId
                name
                viewableBy {
                    id
                    name
                }
                lessons {
                    id
                    lessonId
                    name
                    started
                    finished
                    video {
                        id
                        thumbnailURL
                    }
                }
            }
            members {
                id
                name
                surname
                email
                certificates {
                    id
                    course {
                        id
                        courseId
                    }
                }
                inProgressCourses {
                    id
                    courseId
                    name
                    description
                    lessons {
                        id
                        lessonId
                        name
                        started
                        finished
                    }
                }
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export interface DeleteWorkGroupInput {
    input: {
        workGroupId: string
    }
}

export interface DeleteWorkGroupResponse {
    deleteWorkGroup: string
}

export const DELETE_WORKGROUP = gql`
    mutation DeleteWorkGroup($input: DeleteWorkGroupInput!) {
        deleteWorkGroup(input: $input)
    }
`

export interface EditWorkGroupInput {
    input: {
        id: string
        name?: string
    }
}

export interface EditWorkGroupResponse {
    editWorkGroup: ManagedWorkGroup
}

export const EDIT_WORKGROUP = gql`
    mutation EditWorkGroup($input: EditWorkGroupInput!) {
        editWorkGroup(input: $input) {
            id
            name
        }
    }
`

export interface AddCoursesToWorkGroupInput {
    input: {
        workGroupID: string
        coursesIDs: string[]
    }
}

export interface AddCoursesToWorkGroupResponse {
    addCoursesToWorkGroup: ManagedWorkGroup
}

export const ADD_COURSES_WORKGROUP = gql`
    mutation AddCoursesToWorkGroup($input: AddCoursesToWorkGroupInput!) {
        addCoursesToWorkGroup(input: $input) {
            id
            name
            requiredCourses {
                id
                courseId
                name
                viewableBy {
                    id
                    name
                }
                lessons {
                    id
                    lessonId
                    name
                    started
                    finished
                    video {
                        id
                        thumbnailURL
                    }
                }
            }
            members {
                id
                name
                surname
                email
                certificates {
                    id
                    course {
                        id
                        courseId
                    }
                }
                inProgressCourses {
                    id
                    courseId
                    name
                    description
                    lessons {
                        id
                        lessonId
                        name
                        started
                        finished
                    }
                }
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export interface RemoveCoursesFromWorkGroupInput {
    input: {
        workGroupID: string
        coursesIDs: string[]
    }
}

export interface RemoveCoursesFromWorkGroupResponse {
    removeCoursesFromWorkGroup: ManagedWorkGroup
}

export const REMOVE_COURSES_WORKGROUP = gql`
    mutation RemoveCoursesFromWorkGroup(
        $input: RemoveCoursesFromWorkGroupInput!
    ) {
        removeCoursesFromWorkGroup(input: $input) {
            id
            name
            requiredCourses {
                id
                courseId
                name
                lessons {
                    id
                    lessonId
                    name
                    started
                    finished
                    video {
                        id
                        thumbnailURL
                    }
                }
            }
            members {
                id
                name
                surname
                email
                certificates {
                    id
                    course {
                        id
                        courseId
                    }
                }
                inProgressCourses {
                    id
                    courseId
                    name
                    description
                    lessons {
                        id
                        lessonId
                        name
                        started
                        finished
                    }
                }
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export interface GetManagedWorkGroupInput {
    input: {
        managedWorkGroupID: string
    }
}

export interface GetManagedWorkGroupResponse {
    managedWorkGroup: ManagedWorkGroup
}

export const GET_MANAGED_WORKGROUP = gql`
    query GetManagedWorkGroup($input: GetManagedWorkGroupInput!) {
        managedWorkGroup(input: $input) {
            id
            name
            requiredCourses {
                id
                courseId
                name
                viewableBy {
                    id
                    name
                }
                lessons {
                    id
                    lessonId
                    name
                    started
                    finished
                    video {
                        id
                        thumbnailURL
                    }
                }
            }
            members {
                id
                name
                surname
                email
                certificates {
                    id
                    course {
                        id
                        courseId
                    }
                }
                inProgressCourses {
                    id
                    courseId
                    name
                    description
                    lessons {
                        id
                        lessonId
                        name
                        started
                        finished
                    }
                }
                userGroups {
                    id
                    name
                }
            }
        }
    }
`

export interface SearchUsersInput {
    skip?: number
    take?: number
    text: string
}

export interface SearchUsersResponse {
    searchUsers: {
        items: Person[]
        totalCount: number
        pageInfo: {
            hasNextPage: boolean
            hasPreviousPage: boolean
        }
    }
}

export const SEARCH_USERS = gql`
    query SearchUsers($text: String!, $skip: Int, $take: Int) {
        searchUsers(text: $text, skip: $skip, take: $take) {
            items {
                id
                name
                surname
                email
                userGroups {
                    id
                    name
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

export interface SearchCoursesInput {
    text: string
}

export interface SearchCoursesResponse {
    search: {
        items: StrippedCourse[]
        totalCount: number
    }
}

export const SEARCH_COURSES = gql`
    query SearchCourses($text: String!) {
        search(text: $text, contentType: [COURSE]) {
            totalCount
            items {
                id
                name
                description

                ... on Course {
                    courseId
                    viewableBy {
                        id
                        name
                    }
                    lessons {
                        id
                        name
                        video {
                            id
                            uuid
                        }
                    }
                }
            }
        }
    }
`
