import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_en_us from './translations/en-US/common.json'
import lesson_en_us from './translations/en-US/lesson.json'
import course_en_us from './translations/en-US/course.json'
import search_en_us from './translations/en-US/search.json'
import tags_en_us from './translations/en-US/tags.json'
import profile_en_us from './translations/en-US/profile.json'
import explore_en_us from './translations/en-US/explore.json'
import bookmarks_en_us from './translations/en-US/bookmarks.json'
import test_en_us from './translations/en-US/test.json'
import certificate_en_us from './translations/en-US/certificate.json'
import manwg_en_us from './translations/en-US/manageWorkGroup.json'
import groups_en_us from './translations/en-US/groups.json'

import common_it_IT from './translations/it-IT/common.json'
import lesson_it_IT from './translations/it-IT/lesson.json'
import course_it_IT from './translations/it-IT/course.json'
import search_it_IT from './translations/it-IT/search.json'
import tags_it_IT from './translations/it-IT/tags.json'
import profile_it_IT from './translations/it-IT/profile.json'
import explore_it_IT from './translations/it-IT/explore.json'
import bookmarks_it_IT from './translations/it-IT/bookmarks.json'
import test_it_IT from './translations/it-IT/test.json'
import certificate_it_IT from './translations/it-IT/certificate.json'
import manwg_it_IT from './translations/it-IT/manageWorkGroup.json'
import groups_it_IT from './translations/it-IT/groups.json'

import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'

import { getStoredLocale } from './utils/localstorage'
import {
    createTheme,
    responsiveFontSizes,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material'
import { getCurrentLocale } from './utils/locale'
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { LinkProps } from '@mui/material/Link'

i18next.init({
    interpolation: { escapeValue: false },
    lng: getStoredLocale()?.code,
    fallbackLng: 'en-US',
    resources: {
        'en-US': {
            common: common_en_us,
            lesson: lesson_en_us,
            course: course_en_us,
            search: search_en_us,
            tags: tags_en_us,
            profile: profile_en_us,
            explore: explore_en_us,
            bookmarks: bookmarks_en_us,
            test: test_en_us,
            certificate: certificate_en_us,
            manageWorkGroup: manwg_en_us,
            groups: groups_en_us,
        },
        'it-IT': {
            common: common_it_IT,
            lesson: lesson_it_IT,
            course: course_it_IT,
            search: search_it_IT,
            tags: tags_it_IT,
            profile: profile_it_IT,
            explore: explore_it_IT,
            bookmarks: bookmarks_it_IT,
            test: test_it_IT,
            certificate: certificate_it_IT,
            manageWorkGroup: manwg_it_IT,
            groups: groups_it_IT,
        },
    },
})

// MSAL configuration
const configuration: Configuration = {
    auth: {
        clientId: '545a705b-7525-4574-962c-70254e74ac2b',
        authority:
            'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd',
        redirectUri: '/',
        postLogoutRedirectUri: 'https://www.abb.com',
    },
}

const pca = new PublicClientApplication(configuration)

const LinkBehavior = React.forwardRef<
    any,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props
    if (href && href.startsWith('http')) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a href={href} {...other} />
    } else {
        // Map href (MUI) -> to (react-router)
        return <RouterLink ref={ref} to={href} {...other} />
    }
})

const theme = responsiveFontSizes(
    createTheme(
        {
            palette: {
                primary: {
                    main: '#6e6e6e',
                },
                secondary: {
                    main: '#ff000f',
                },
            },
            typography: {
                fontFamily: ['ABBVoice', 'sans-serif'].join(','),
                h1: {
                    fontWeight: 500,
                    fontSize: '4em',
                },
            },
            components: {
                MuiLink: {
                    defaultProps: {
                        component: LinkBehavior,
                    } as LinkProps,
                },
                MuiButtonBase: {
                    defaultProps: {
                        LinkComponent: LinkBehavior,
                    },
                },
            },
        },
        getCurrentLocale().muiLocale
    )
)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <MsalProvider instance={pca}>
            <I18nextProvider i18n={i18next}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </StyledEngineProvider>
            </I18nextProvider>
        </MsalProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
