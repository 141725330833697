import React from 'react'
import { Permission } from '../utils/types'
import PageWrapper from './PageWrapper'
import PageNotFound from './errors/PageNotFound'

type PermissionGatedComponentProps = {
    requiredPermissionsNames: string[] // these all need to be present on the user
    user?: {
        completeName: string
        email: string
        error: boolean
        permissions: Permission[]
    }
    silentlyFail?: boolean
    children: React.ReactElement
}

export function PermissionGatedComponent(props: PermissionGatedComponentProps) {
    return props.user &&
        props.requiredPermissionsNames.every((rpName) =>
            props.user?.permissions.find((p) => p.name === rpName)
        ) ? (
        props.children
    ) : !props.silentlyFail ? (
        <PageWrapper>
            <PageNotFound />
        </PageWrapper>
    ) : null
}
