import React, { useContext } from 'react'
import {
    Avatar,
    Box,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NotificationMenu } from './NotificationMenu'
import { DevSettingsContext } from '../utils/DevSettingsContext'

type Props = {
    buttons?: (
        | {
              text: string
              urn: string
              icon: any // TODO: actually use types
          }
        | undefined
    )[]
    user: {
        completeName: string
        email: string
        error: boolean
    }
    showCompleteName?: boolean
}

export function TopBarProfileMenu(props: Props) {
    const devSettingsContext = useContext(DevSettingsContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [t] = useTranslation('common')

    return (
        <div className="user-profile-container">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {props.showCompleteName && (
                    <Typography sx={{ minWidth: 100 }}>
                        {props.user.error ? (
                            <Skeleton />
                        ) : (
                            props.user.completeName
                        )}
                    </Typography>
                )}
                {devSettingsContext.showNotificationsEarlyAccess ? (
                    <NotificationMenu />
                ) : null}
                <Tooltip title={t('topBar.avatarTitle') as string}>
                    <IconButton
                        onClick={handleClick}
                        size="medium"
                        sx={{ ml: 2 }}
                    >
                        <Avatar sx={{ width: 50, height: 50 }}>
                            {props.user.error
                                ? undefined
                                : props.user.completeName.split(' ')[0][0] +
                                  props.user.completeName.split(' ')[1][0]}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 50,
                            height: 50,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 25,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.buttons
                    ? props.buttons.map((button, index) =>
                          button ? (
                              <Link
                                  sx={{
                                      textDecoration: 'none',
                                      color: 'inherit',
                                  }}
                                  href={button.urn}
                                  key={index}
                              >
                                  <MenuItem sx={{ height: 65, width: 250 }}>
                                      <ListItemText>{button.text}</ListItemText>
                                      <ListItemIcon>
                                          {React.cloneElement(button.icon)}
                                      </ListItemIcon>
                                  </MenuItem>
                              </Link>
                          ) : undefined
                      )
                    : null}
            </Menu>
        </div>
    )
}

export default TopBarProfileMenu
