import React, { useContext } from 'react'
import { useAuthToken } from '../utils/auth'
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client'
import { API_URI } from '../utils/api'
import { setContext } from '@apollo/client/link/context'
import { getCurrentLocale } from '../utils/locale'
import { DevSettingsContext } from '../utils/DevSettingsContext'
import { FullScreenLoader } from './FullScreenLoader'

type Props = {
    children: React.ReactNode
}

export function ApolloWrapper(props: Props) {
    const token = useAuthToken()

    const devSettingsContext = useContext(DevSettingsContext)

    if (token === null) {
        return <FullScreenLoader />
    } else {
        console.log(token)
        const httpLink = createHttpLink({
            uri: API_URI,
        })

        const authLink = setContext((_, { headers }) => {
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${token}` : '',
                    'Accept-Language': getCurrentLocale().code,
                    'Accept-Encoding': 'gzip',
                    ...(devSettingsContext.spoofEmailAddress
                        ? { 'X-View-As': devSettingsContext.fakeEmailAddress }
                        : undefined),
                },
            }
        })

        const client = new ApolloClient({
            connectToDevTools: true,
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                possibleTypes: {
                    SearchResult: ['Course', 'Lesson'],
                },
            }),
        })

        return <ApolloProvider client={client}>{props.children}</ApolloProvider>
    }
}

export default ApolloWrapper
