import React, { useCallback, useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/img/logo-small.png'
import TopBarButton from './TopBarButton'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'

import TopBarProfileMenu from './TopBarProfileMenu'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'
import { locales } from '../utils/locale'
import GroupsIcon from '@mui/icons-material/Groups'
import StarIcon from '@mui/icons-material/Star'
import { DevSettingsContext } from '../utils/DevSettingsContext'
import CodeIcon from '@mui/icons-material/Code'
import {
    AppBar,
    Avatar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Slide,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { SMALL_SCREEN_BREAKPOINT, useWindowSize } from '../utils/helpers'
import HomeIcon from '@mui/icons-material/Home'
import { Permission } from '../utils/types'
import { NotificationMenu } from './NotificationMenu'

type Props = {
    buttons?: {
        text: string
        urn: string
        icon: React.ReactElement
    }[]
    user?: {
        completeName: string
        email: string
        error: boolean
        permissions: Permission[]
    }
    title?: string
}

type HideOnScrollProps = {
    children: React.ReactElement
}

function HideOnScroll(props: HideOnScrollProps) {
    const { children } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger()

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
}

const SmallScreensTopBar = (props: Props) => {
    const [t] = useTranslation('common')

    const devSettingsContext = useContext(DevSettingsContext)

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

    const toggleDrawer = useCallback(() => {
        setDrawerOpen((prevOpen) => !prevOpen)
    }, [])

    const dropdownButtons = [
        props.user?.permissions.find((p) => p.name === 'GROUP_CREATE')
            ? {
                  text: t('topBar.dropdown.groups'),
                  urn: '/classes',
                  icon: <GroupsIcon />,
              }
            : undefined,
        {
            text: t('topBar.dropdown.certificates'),
            urn: '/certificates',
            icon: <SchoolIcon />,
        },
        {
            text: t('topBar.dropdown.bookmarks'),
            urn: '/bookmarks',
            icon: <StarIcon />,
        },
        devSettingsContext.isDeveloper
            ? {
                  text: t('topBar.dropdown.dev'),
                  urn: '/dev-settings',
                  icon: <CodeIcon />,
              }
            : undefined,
        {
            text: t('topBar.dropdown.signOut'),
            urn: '/logout',
            icon: <ExitToAppIcon />,
        },
    ]

    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar sx={{ backgroundColor: '#f0f0f0', color: '#000000' }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Link
                            to="/"
                            style={{
                                flexGrow: 1,
                                textDecoration: 'none',
                                color: 'inherit',
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: 'center' }}
                            >
                                <img
                                    src={logo}
                                    alt="ABB Logo"
                                    height={35}
                                    width={35}
                                    style={{
                                        marginBottom: 5,
                                    }}
                                />
                                <Typography variant="h6">
                                    {t('appName')}
                                </Typography>
                            </Stack>
                        </Link>
                        {devSettingsContext.showNotificationsEarlyAccess ? (
                            <NotificationMenu />
                        ) : null}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Drawer
                id="drawer"
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
            >
                <Box
                    sx={{ width: 300, height: '100%' }}
                    role="presentation"
                    onKeyDown={toggleDrawer}
                >
                    <Stack sx={{ height: '100%' }} spacing={2}>
                        {props.user && (
                            <Link
                                onClick={toggleDrawer}
                                to="/profile"
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    sx={{
                                        paddingLeft: 2,
                                        paddingTop: 5,
                                        paddingBottom: 2,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar sx={{ width: 50, height: 50 }}>
                                        {props.user.error
                                            ? undefined
                                            : props.user.completeName.split(
                                                  ' '
                                              )[0][0] +
                                              props.user.completeName.split(
                                                  ' '
                                              )[1][0]}
                                    </Avatar>

                                    <Typography noWrap>
                                        {props.user.error ? (
                                            <Skeleton width={100} />
                                        ) : (
                                            props.user.completeName
                                        )}
                                    </Typography>
                                </Stack>
                            </Link>
                        )}
                        {props.buttons && (
                            <List onClick={toggleDrawer}>
                                <NavLink
                                    exact
                                    key="/"
                                    to="/"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <ListItem className="drawer-link" button>
                                        <ListItemIcon>
                                            <HomeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('home')} />
                                    </ListItem>
                                </NavLink>
                                {props.buttons.map((button, index) => (
                                    <NavLink
                                        key={button.urn}
                                        to={button.urn}
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <ListItem
                                            className="drawer-link"
                                            button
                                        >
                                            <ListItemIcon>
                                                {button.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={button.text}
                                            />
                                        </ListItem>
                                    </NavLink>
                                ))}
                                <Divider />
                                {dropdownButtons.map((button, index) => {
                                    if (button) {
                                        return (
                                            <NavLink
                                                key={button.urn}
                                                to={button.urn}
                                                style={{
                                                    color: 'inherit',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <ListItem
                                                    className="drawer-link"
                                                    button
                                                >
                                                    <ListItemIcon>
                                                        {button.icon}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={button.text}
                                                    />
                                                </ListItem>
                                            </NavLink>
                                        )
                                    } else {
                                        return undefined
                                    }
                                })}
                            </List>
                        )}
                        <LanguageSwitcher
                            style={{
                                maxWidth: 150,
                                paddingLeft: 15,
                            }}
                            locales={locales}
                        />
                    </Stack>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export function TopBar(props: Props) {
    const [t] = useTranslation('common')

    const devSettingsContext = useContext(DevSettingsContext)
    const [width] = useWindowSize()

    if (width <= SMALL_SCREEN_BREAKPOINT) {
        return <SmallScreensTopBar {...props} />
    }

    return (
        <nav id="top-bar">
            <Link to="/" id="logo">
                <img width={60} height={60} src={logo} alt="ABB Logo" />
            </Link>
            <LanguageSwitcher
                className="topbar-lang-switcher"
                locales={locales}
            />
            {props.buttons ? (
                <div className="topbar-button-container">
                    {props.buttons.map((button) => (
                        <TopBarButton
                            text={button.text}
                            urn={button.urn}
                            key={button.text + button.urn}
                        />
                    ))}
                </div>
            ) : null}
            {props.user ? (
                <TopBarProfileMenu
                    showCompleteName
                    buttons={[
                        {
                            text: t('topBar.dropdown.profile'),
                            urn: '/profile',
                            icon: <PersonIcon />,
                        },
                        props.user.permissions.find(
                            (p) => p.name === 'GROUP_CREATE'
                        )
                            ? {
                                  text: t('topBar.dropdown.groups'),
                                  urn: '/classes',
                                  icon: <GroupsIcon />,
                              }
                            : undefined,
                        {
                            text: t('topBar.dropdown.certificates'),
                            urn: '/certificates',
                            icon: <SchoolIcon />,
                        },
                        {
                            text: t('topBar.dropdown.bookmarks'),
                            urn: '/bookmarks',
                            icon: <StarIcon />,
                        },
                        devSettingsContext.isDeveloper
                            ? {
                                  text: t('topBar.dropdown.dev'),
                                  urn: '/dev-settings',
                                  icon: <CodeIcon />,
                              }
                            : undefined,
                        {
                            text: t('topBar.dropdown.signOut'),
                            urn: '/logout',
                            icon: <ExitToAppIcon />,
                        },
                    ]}
                    user={props.user}
                />
            ) : null}
        </nav>
    )
}

export default TopBar
