import { keyframes, Stack } from '@mui/material'
import React from 'react'
import { Logo } from './Logo'

export function FullScreenLoader() {
    const loadingEffect = keyframes`
        0% {
          transform: scale(0.90);
        }
        
        70% {
          transform: scale(1);
        }
        
        100% {
          transform: scale(0.90);
        }
    `

    return (
        <Stack
            height="100vh"
            width="100vw"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundColor: '#ffffff',
                animation: `${loadingEffect} 1s alternate infinite`,
            }}
        >
            <Logo width={300} height={150} />
        </Stack>
    )
}
